.custom-marker-label {
    background-color: white;
    color: black;
    font-size: 11px;
    font-weight: bold;
    padding: 1px;
    border-radius: 100%;
    border: 1px solid #55B8FF;
    margin-bottom: 55px;
    height: 20px;
    width: 20px;
    line-height: 16px;
  }
  